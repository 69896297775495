.home {
  width: 100%;
  height: 100%;
  // position: fixed;
  // left: 0;
  // top: 0;
}

* {
  scroll-behavior: smooth;
}

.video_header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
  background: #343a40;
}

.video_header video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home_AI {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  z-index: 1;
}

// .home_AI::-webkit-scrollbar {
//   width: 10px;
//   height: 10px;
//   border-top-right-radius: 12px;
//   border-bottom-right-radius: 12px;
// }
// .home_AI::-webkit-scrollbar-thumb {
//   background-color: #000;
//   // background-color: transparent;
//   border-radius: 12px;
// }
// .home_AI::-webkit-scrollbar-thumb:hover {
//   background-color: #555;
// }
.home_AI_header {
  width: 100%;
  height: 80px;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(0);
  transform-origin: top left;
  transition: transform ease-out 250ms;
  transition-delay: 0ms;
}

.Atomgradient_icon {
  position: absolute;
  top: 0;
  width: 80px;
  height: 80px;
}

.Atomgradient_icon_footer {
  position: absolute;
  top: 35px;
  width: 80px;
  height: 80px;
}

.home_AI_headers {
  width: 100%;
  height: 80px;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: top left;
  transition: transform ease-out 250ms;
  transform: translateY(-80px);
  transition-delay: 250ms;
}

.home_AI_header_table {
  width: 200px;
  display: flex;
  justify-content: space-between;
}


.privacy {
  height: 25px;
  position: relative;
  font-weight: 600;
}

.privacy::before {
  content: "";
  position: absolute;
  bottom: -3px;
  right: 0;
  width: 0;
  height: 1.5px;
  /* 底部边框高度 */
  background-color: #fff;
  /* 底部边框颜色 */
  transition: width 0.5s ease;
  /* 动画持续时间和过渡效果 */
}

.privacy:hover::before {
  width: 100%;
  /* 在:hover时将宽度设置为100% */
}

.privacys::before {
  width: 100%;
}


.terms {
  height: 25px;
  position: relative;
  font-weight: 600;
}

.terms::before {
  content: "";
  position: absolute;
  bottom: -3px;
  right: 0;
  width: 0;
  height: 1.5px;
  /* 底部边框高度 */
  background-color: #fff;
  /* 底部边框颜色 */
  transition: width 0.5s ease;
  /* 动画持续时间和过渡效果 */
}

.terms:hover::before {
  width: 100%;
  /* 在:hover时将宽度设置为100% */
}

.termss::before {
  width: 100%;
}


.about {
  height: 25px;
  position: relative;
  font-weight: 600;
}

.about::before {
  content: "";
  position: absolute;
  bottom: -3px;
  right: 0;
  width: 0;
  height: 1.5px;
  /* 底部边框高度 */
  background-color: #fff;
  /* 底部边框颜色 */
  transition: width 0.5s ease;
  /* 动画持续时间和过渡效果 */
}

.about:hover::before {
  width: 100%;
  /* 在:hover时将宽度设置为100% */
}

.abouts::before {
  width: 100%;
}



.joinus {
  height: 25px;
  font-weight: 600;
  position: relative;
}

.joinus::before {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 1.5px;
  /* 底部边框高度 */
  background-color: #fff;
  /* 底部边框颜色 */
  transition: width 0.5s ease;
  /* 动画持续时间和过渡效果 */
}

.joinus:hover::before {
  width: 100%;
  /* 在:hover时将宽度设置为100% */
}

.joinuss::before {
  width: 100%;
}

.zhezhao {
  width: 100%;
  height: 60%;
  backdrop-filter: blur(2px);
  color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.zhezhao_title {
  display: flex;
  flex-wrap: wrap;
}

.zhezhao_about {
  display: flex;
  flex-wrap: wrap;
}

.zhezhao_humanity {
  display: flex;
  flex-wrap: wrap;
}

.reveal {
  font-size: 25px;
  animation: reveal 2s ease forwards 750ms;
  opacity: 0;
  margin-top: 1rem;
}

@keyframes reveal {
  0% {
    transform: translateY(25px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.zhezhao_title span {
  font-size: 3rem;
  font-weight: 600;
  margin-left: 20px;
  animation: tokens 1s ease-out forwards;
  opacity: 0;
  border-radius: 12px;
  padding: 10px;
}

.zhezhao_title span:nth-child(1) {
  animation-delay: 250ms;
}

.zhezhao_title span:nth-child(2) {
  animation-delay: 500ms;
}

.zhezhao_about span {
  font-size: 3rem;
  font-weight: 600;
  margin-left: 20px;
  animation: tokenone 1s ease-out forwards;
  opacity: 0;
  border-radius: 12px;
  padding: 10px;
}

.zhezhao_about span:nth-child(1) {
  animation-delay: 150ms;
}

.zhezhao_about span:nth-child(2) {
  animation-delay: 300ms;
}

.zhezhao_about span:nth-child(3) {
  animation-delay: 450ms;
}

.zhezhao_about span:nth-child(4) {
  animation-delay: 600ms;
}

.zhezhao_humanity span {
  font-size: 3rem;
  font-weight: 600;
  margin-left: 20px;
  animation: tokenstwo 1s ease-out forwards;
  opacity: 0;
  border-radius: 12px;
  padding: 10px;
}

.zhezhao_humanity span:nth-child(1) {
  animation-delay: 150ms;
}

.zhezhao_humanity span:nth-child(2) {
  animation-delay: 300ms;
}

.zhezhao_humanity span:nth-child(3) {
  animation-delay: 450ms;
}

.zhezhao_humanity span:nth-child(4) {
  animation-delay: 600ms;
}

.zhezhao_humanity span:nth-child(5) {
  animation-delay: 750ms;
}

.zhezhao_humanity span:nth-child(6) {
  animation-delay: 900ms;
}

.zhezhao_humanity span:nth-child(7) {
  animation-delay: 1050ms;
}

@keyframes tokens {
  0% {
    opacity: 0;
    background: #cccccc40;
  }

  100% {
    opacity: 1;
  }
}

@keyframes tokenone {
  0% {
    opacity: 0;
    background: #cccccc40;
  }

  100% {
    opacity: 1;
  }
}

@keyframes tokenstwo {
  0% {
    opacity: 0;
    background: #cccccc40;
  }

  100% {
    opacity: 1;
  }
}

.home_AI_container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  // height: 150%;
  z-index: 1;
  background: #fff;
  padding: 0 0 50px 0;
}

.home_AI_containersdaigyi {
  max-width: 900px;
  margin-top: 30px;

  li {
    margin-top: 10px;
  }

  li::marker {
    color: #1456f0;
  }

  h2 {
    margin: 20px 0;
  }
}

.home_AI_footer {
  height: 150px;
  backdrop-filter: blur(10px);
  position: relative;
}

.openFeishu {
  background: #e7e9ec;
  border-radius: 6px;
  padding: 0px 20px;
  height: 70px;
  // line-height: 50px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #343a40;
  font-size: 0.9rem;
}

.openFeishu:hover {
  background: #cccfd3;
}

.teamnember {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  // align-items: center;
  gap: 15px;
}

.teamPeo {
  width: 32%;
  display: flex;
  // justify-content: center;
  align-items: center;
  background: #e9ecef;
  height: 80px;
  border-radius: 12px;
}

.teamPeo:hover {
  background: #ced4da;
  color: #343a40;
}

@media only screen and (max-width: 767px) {
  /* 在小于或等于767px宽度的屏幕上应用以下样式 */

  /* 例如，更改字体大小和布局 */
  .home_AI_header_table {
    width: 150px;
  }

  .zhezhao {
    height: 70%;
  }

  .zhezhao_title span {
    font-size: 2rem;
    font-weight: 600;
    margin-left: 1rem;
    animation: tokens 1s ease-out forwards;
    opacity: 0;
    border-radius: 12px;
    padding: 0.5rem;
    // margin-top: 1rem;
  }

  .zhezhao_about span {
    font-size: 2rem;
    font-weight: 600;
    margin-left: 1rem;
    animation: tokenone 1s ease-out forwards;
    opacity: 0;
    border-radius: 12px;
    padding: 0.5rem;
    // margin-top: 1rem;
  }

  .zhezhao_humanity span {
    font-size: 2rem;
    font-weight: 600;
    margin-left: 1rem;
    animation: tokenstwo 1s ease-out forwards;
    opacity: 0;
    border-radius: 12px;
    padding: 0.5rem;
    // margin-top: 1rem;
  }

  .reveal {
    margin-left: 1.5rem;
    font-size: 1rem;
  }

  .home_AI_container {
    width: auto;
    padding: 1.5rem 2rem 3rem 2rem;
  }

  .home_AI_containersdaigyi {
    margin-top: 0;

    h1 {
      font-size: 1.3rem;
    }

    h2 {
      font-size: 1.1rem;
    }
  }

  .teamnember {
    display: flex;
    flex-direction: column;
  }

  .teamPeo {
    width: 100%;
    height: 4rem;
  }

  .mobileBack {
    background-image: url("../../static/mobileback.gif");
    /* 更换为您的图片文件路径 */
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
  }

  .openFeishu:hover {
    background: #e7e9ec;
  }

  .teamPeo {
    margin: 7.5px;
  }
}

// .home_AI::-webkit-scrollbar {
//   width: 10px; /* 设置滚动条轨道的宽度 */
// }
// /* 自定义滚动条的轨道 */
// ::-webkit-scrollbar-track {
//   background-color: #f1f1f1; /* 设置滚动条轨道的背景颜色 */
// }

// ::-webkit-scrollbar-thumb {
//   background-color: #fff; /* 设置滑块的背景颜色 */
//   border-radius: 5px; /* 设置滑块的圆角 */
// }