.privacy-policy {
    font-family: 'Arial', sans-serif;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .privacy-policy h1 {
    color: #333;
    font-size: 24px;
  }
  
  .privacy-policy p {
    color: #666;
    line-height: 1.6;
  }
  
  /* 标题样式 */
  .privacy-policy h2 {
    color: #333;
    font-size: 20px;
    margin-top: 20px;
  }
  
  /* 段落样式 */
  .privacy-policy p {
    margin-bottom: 10px;
  }
  