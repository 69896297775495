.videogast_home {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
}
.videogast_header {
  position: fixed;
  top: 0;
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
  //   border-bottom: 1px solid #ccc;
  background-color: hsla(0, 0%, 100%, 0.95);
  border-bottom: 0.5px solid #ccc;
  //   display: flex;
  height: 70px;
  //   justify-content: center;
  //   padding-left: 300px;
  position: absolute;
  top: 0;
  transition: all 0.3s linear;
  width: 100%;
  z-index: 500;
  //   transform: translateY(-50px);
}
.videogast_headers {
  position: fixed;
  top: 0;
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
  background-color: hsla(0, 0%, 100%, 0.95);
  border-bottom: 0.5px solid #ccc;
  height: 70px;
  position: absolute;
  top: 0;
  transition: all 0.3s linear;
  width: 100%;
  z-index: 500;
  transform: translateY(-70px);
}
.videogast_home_logo {
  //   font-weight: 800;
  //   font-size: 20px;
}
.videogast_container {
  width: 100%;
  height: 100%;
  //   margin-top: 70px;
  //   display: flex;
  //   flex-direction: column;
  overflow-y: auto;
}
.dsanhausbdabsduiashbdua {
  width: 100%;
  height: calc(100% - 50px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.videogast_container_headedasdaib {
  margin-bottom: 100px;
}
.videogast_container_header {
  font-weight: 800;
  font-size: 35px;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.videogast_container_input {
  display: flex;
  height: 50px;
  display: flex;
  align-items: center;
  min-width: 600px;
}
.videogast_container_input_dasdvghcvyf {
  outline: none;
  border: 1px solid #ccc;
  width: calc(100% - 120px);
  padding-left: 15px;
  padding-right: 15px;
  height: 100%;
  border-radius: 12px;
}
.videogast_container_button {
  width: 120px;
  background-color: #000;
  height: 100%;
  border-radius: 10px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  font-weight: 600;
}
.gdyasvgtdvaygtvdyascvfdcvay {
  display: flex;
  height: 36px;
  display: flex;
  align-items: center;
  min-width: 300px;
  position: absolute;
  right: 20px;
  transform: scale(0); /* 初始缩放为0 */
  transform-origin: right center;
  animation: showAndScale 0.5s forwards;
  .videogast_container_input_dasdvghcvyf {
    border-radius: 6px;
  }
  .videogast_container_button {
    width: 94px;
    font-weight: 400;
    border-radius: 6px;
  }
}
@keyframes showAndScale {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1); /* 最终缩放为1 */
  }
}
.videogast_container_list {
  width: 100%;
  //   padding: 0 20px 20px 20px;
  display: flex;
  justify-content: center;
}
.dajsiodabgiydgyvg {
  height: 50px;
  font-weight: 800;
  font-size: 25px;
  display: flex;
  align-items: center;
}
.ndbvtcdyasvbdabvdutytvu {
  max-width: 1152px;
}
.dabdgsygayvtfyrdrc {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.dasgdvfcytcd {
  width: 30%;
  margin-top: 20px;
}
.imgdasvdbacvgdcyhg {
  width: 100%;
  border-radius: 12px;
}
.dsnaoubdiabdhasv {
  font-weight: 800;
  color: #244bd1;
}
.dasjodabbvdvsuvcycy {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 70px;
  padding: 70px 0 0 0;
}
.sdnbsvatcvdavsd {
  width: 848px;
}
.asdnahvdcyatsdyfcvgbvygt {
  display: flex;
  justify-content: space-between;
}
.sdnbajvdsvdugtvgt {
  display: flex;
  max-width: 130px;
  width: 20%;
  justify-content: space-between;
  div {
    width: 60px;
    border: #ccc 1px solid;
    height: 30px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: #244bd1;
    box-shadow: 0px 0px 1px 0px #ccc;
  }
}
.sdabhjbvdsghsvagcvfcfc {
  //   width: 80%;
}
.sdbvvtcdrcvknijhiuh {
  width: 80%;
}
.dabhjvdhgcy {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  border: 1px #ccc solid;
}
.sdadnsaisbdyuvfsdty {
  display: flex;
  align-items: center;
  color: #ccc;
}
.sdnajbduvyafstudcfvtay {
  margin-top: 30px;
}
.sdbavuvcdstycadyrc {
  margin-top: 50px;
  ul {
    margin-left: 15px;
    li {
      margin-top: 10px;
    }
  }
}
.sandhavdagascfdcaxtdcvyacy {
  margin-top: 70px;
}
.dashbvdavduvadvcastyc {
  display: flex;
  justify-content: space-between;
  .sdavdtyacdyrcaytdfvsbdvvcyrcvu {
    width: 60%;
  }
  img {
    width: 30%;
    height: 140px;
    border-radius: 12px;
    margin-top: 40px;
  }
}
.sdbavyvdtyacyrrfvyu {
  display: flex;
  justify-content: space-between;
}
.sdbajhdvghavdtvc {
  width: 80%;
}
.daysfdtfatdtafv {
  width: 60px;
  border: #ccc 1px solid;
  height: 30px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: #244bd1;
  box-shadow: 0px 0px 1px 0px #ccc;
}
.dansjdnbavsdtuvsatcyfrcv {
  margin-top: 20px;
}
.sdaygduyaudtsvfvbhniuhuyftccvyg {
  margin-top: 10px;
  margin-left: 15px;
}
