.atomgradientVideo {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
}
#video-player {
  width: 100%;
  height: 100%;
  object-fit: fill;
  //   border-radius: 12px;
  outline: none;
  position: relative;
}
.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  /* 添加其他样式属性 */
}
