.assistantHome {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  // font-family: normal fonts with serifs Times, Times New Roman, serif;
  overflow-y: auto;
  color: #2e3863;
  background-image: linear-gradient(
    45deg,
    #dddcf4 0%,
    #e0dff5 25%,
    #f4f3f6 50%,
    #f4f3f6 75%,
    #f4f4f6 100%
  );
}
.assistantHome::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}
.assistantHome::-webkit-scrollbar-thumb {
  background-color: #9ba1f7;
  border-radius: 12px;
}
.assistantHomeheader {
  width: 100%;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 23px;
}
.assistantHomeheadercontainer {
  width: 100%;
  display: flex;
  justify-content: center;
  div {
    max-width: 1000px;
    text-indent: 2em;
  }
}
.assistantHomeContainer {
  margin-top: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  .assistantHomeContainerTitle {
    font-weight: 500;
    font-size: 20px;
  }
  .assistantHomeContainerText {
    margin-top: 20px;
    max-width: 1000px;
    // min-width: 50%;
    textarea {
      width: 1000px;
      min-width: 50%;
      resize: none;
      outline: none;
      padding: 1em;
      border-radius: 6px;
      transition: all 0.3s;
      font-size: 16px;
      border: none;
    }
    textarea::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
    }
    textarea::-webkit-scrollbar-thumb {
      background-color: #333;
      border-radius: 12px;
    }
  }
  .assistantHomeContainerButton {
    margin: 20px 0;
    border: none;
    padding: 10px 20px;
    background: linear-gradient(90deg, #9ba1f7 10%, #4474ea 100%);
    border-radius: 6px;
    color: #fff;
    transition: box-shadow 0.3s ease;
    box-shadow: none;
  }
  //   .assistantHomeContainerButton:focus-within {
  //     outline: none; /* 移除按钮的默认聚焦样式 */
  //     box-shadow: 0 0 10px 5px #000; /* 添加阴影效果 */
  //   }
  //   .assistantHomeContainerButton:active {
  //     box-shadow: none;
  //   }
}
@media (max-width: 1000px) {
  .assistantHomeheadercontainer {
    padding: 0 1em;
  }
  .assistantHomeContainer {
    margin-top: 30px;
    width: 100%;

    .assistantHomeContainerText {
      width: 80%;
      max-width: 1000px;
      textarea {
        width: 100%;
        // min-width: 700px;
      }
    }
  }
}
@media (max-width: 700px) {
  .assistantHomeheadercontainer {
    padding: 0 1em;
  }
  .assistantHomeContainer {
    margin-top: 30px;
    width: 100%;
    .assistantHomeContainerText {
      max-width: 500px;
      width: 90%;
      textarea {
        width: 100%;
        min-width: 300px;
        resize: none;
        outline: none;
        padding: 1em;
        border-radius: 6px;
      }
    }
  }
}
