html,
body,
#root {
  width: 100%;
  height: 100%;
  font-size: 16px;
}

@font-face {
  font-family: "ZCOOL_XiaoWei";
  src: url("./fontFamily/Crystal-Regular/Crystal-Regular-2.ttf")
    format("truetype"); /* 根据实际情况更改字体文件路径和格式 */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "zh_SONGTI";
  src: url("./fontFamily/HanZiZhiMeiFangSongGBK-MianFei/HanZiZhiMeiFangSongGBK-MianFei\(God-FangSongGBK-free\)-2.ttf")
    format("truetype"); /* 根据实际情况更改字体文件路径和格式 */
  font-weight: normal;
  font-style: normal;
}
/* input {
  font-family: "ZCOOL_XiaoWei";
}
button {
  font-family: "ZCOOL_XiaoWei";
} */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: 1.5;
}
h1 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

a:hover {
  cursor: pointer;
}
html:lang(zh) {
  font-family: "zh_SONGTI", serif;
}
html:lang(en) {
  /* font-family: "ZCOOL_XiaoWei", serif; */
}
