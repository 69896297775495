.secretaryHome {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  color: #2e3863;
  // font-family: normal fonts with serifs Times, Times New Roman, serif;
  overflow-y: auto;
  background-image: linear-gradient(
    45deg,
    #dddcf4 0%,
    #e0dff5 25%,
    #f4f3f6 50%,
    #f4f3f6 75%,
    #f4f4f6 100%
  );
  .ondadhbsadahsbdbbh {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 30px;
  }
}
.secretaryHome::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}
.secretaryHome::-webkit-scrollbar-thumb {
  background-color: #9ba1f7;
  border-radius: 12px;
}
@keyframes sdadhbasdbhdnakj {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.secretaryHomeHeader {
  height: 100px;
  min-height: 100px;
  font-weight: 600;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.secretaryHomeHeaderContainer {
  // font-family: normal fonts with serifs Times, Times New Roman, serif;
  // font-family: Didot, serif;
  text-indent: 2em;
  display: flex;
  justify-content: center;
  div {
    max-width: 1300px;
  }
  // max-width: 1024px;
}
.secretaryHomeContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  .secretaryHomeContainerSAdbahb {
    max-width: 1024px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}
.secretaryHomeContainerList {
  width: 30%;
  height: 200px;
  border: 2px solid #fff;
  border-radius: 12px;
  margin-left: 2.5%;
  margin-top: 2.5%;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  // justify-content: space-evenly;
  align-items: center;
  opacity: 0;
  animation: sdadhbasdbhdnakj 0.7s ease-in-out normal forwards;
  transition: all 0.3s;
  .secretaryHomeContainertitle {
    display: flex;
    justify-content: center;
    height: 50px;
    align-items: center;
    font-weight: 600;
    flex: 1;
  }
  .secretaryHomeContainercontent {
    width: 100%;
    text-indent: 2em;
    flex: 2;
  }
  .secretaryHomeContainerCreate {
    width: 100px;
    max-height: 40px;
    margin-bottom: 10px;
    background: linear-gradient(90deg, #9ba1f7 10%, #4474ea 100%);
    border-radius: 6px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
  .secretaryHomeContainerButton {
    // max-height: 50px;

    flex: 1;
    display: flex;
    // align-items: center;
    div {
      height: 50px;
      width: 100px;
      background: linear-gradient(90deg, #9ba1f7 10%, #4474ea 100%);
      border-radius: 6px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    // margin-bottom: 10%;
  }
}

@media (max-width: 1024px) {
  .secretaryHomeContainerList {
    width: 45%;
  }
}

@media (max-width: 738px) {
  .secretaryHomeContainer {
    // height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-top: 20px;
    .secretaryHomeContainerSAdbahb {
      display: flex;
      flex-direction: column;
    }
  }
  .secretaryHomeContainerList {
    width: 100%;
    height: 200px;
    // border: 1px solid #000;
    border-radius: 12px;
    margin-left: 0;
    margin-top: 2.5%;
    padding: 0 10px;
    .secretaryHomeContainertitle {
      display: flex;
      justify-content: center;
      height: 50px;
      align-items: center;
      font-weight: 600;
    }
    .secretaryHomeContainercontent {
      text-indent: 2em;
    }
  }
}
